import { useState, useEffect } from "react"

const useAudio = audioElement => {
  const [duration, setDuration] = useState(0)
  const [curTime, setCurTime] = useState(1)
  const [isPlaying, setIsPlaying] = useState(false)
  const [clickedTime, setClickedTime] = useState()

  useEffect(() => {
    const audio = audioElement.current

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration)
      setCurTime(audio.currentTime)
    }

    const setAudioTime = () => setCurTime(audio.currentTime)

    const stopAudio = () => setIsPlaying(false)

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData)

    audio.addEventListener("timeupdate", setAudioTime)

    audio.addEventListener("ended", stopAudio)

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime
      setClickedTime(null)
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData)
      audio.removeEventListener("timeupdate", setAudioTime)
      audio.removeEventListener("ended", stopAudio)
    }
  }, [])

  return {
    curTime,
    duration,
    isPlaying,
    setIsPlaying,
    setClickedTime,
  }
}

export default useAudio
