export const actionDict = (dict, fallback) => {
  const source = new Map(dict)
  return key => (key && source.has(key) ? source.get(key) : fallback)
}

const centerX = (target, self) =>
  target.left + target.width / 2 - self.width / 2

const centerY = (target, self) =>
  target.top + target.height / 2 - self.height / 2

const topY = (target, self, pad = 0) => target.top - self.height - pad

const leftX = (target, self, pad = 0) => target.left - self.width - pad

const bottomY = (target, self, pad = 0) => target.bottom + pad

const rightX = (target, self, pad = 0) => target.right + pad

const positionSwitch = actionDict(
  [
    [
      "top",
      (bind, self, pad) => ({
        left: centerX(bind, self),
        top: topY(bind, self, pad),
      }),
    ],
    [
      "bottom",
      (bind, self, pad) => ({
        left: centerX(bind, self),
        top: bottomY(bind, self, pad),
      }),
    ],
    [
      "left",
      (bind, self, pad) => ({
        left: leftX(bind, self, pad),
        top: centerY(bind, self),
      }),
    ],
    [
      "right",
      (bind, self, pad) => ({
        left: rightX(bind, self, pad),
        top: centerY(bind, self),
      }),
    ],
  ],
  () => ({ left: 0, top: 0 })
)

const getTooltipPosition = (tooltip, bind, pos, pad) => {
  if (!tooltip || !bind) return { left: 0, top: 0 }

  const tooltipReact = tooltip.getBoundingClientRect()
  const bindRect = bind.getBoundingClientRect()
  const { left, top } = positionSwitch(pos)(bindRect, tooltipReact, pad)
  return {
    left: Math.max(
      0,
      Math.min(left, window.innerWidth - tooltipReact.width / 2)
    ),
    top,
  }
}

export default getTooltipPosition
