import styled from "styled-components"
import ModalButton from "./ModalButton"

import { FONT } from "src/developers/constants"

export const ModalBody = styled.main`
  padding: 48px 24px;
  padding-bottom: 28px;
  text-align: center;

  h3 {
    font-family: ${FONT.SECONDARY};
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #001553;
  }

  p {
    font-family: ${FONT.PRIMARY};
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    color: #001553;
  }

  > ${ModalButton} {
    display: none;
  }

  @media (max-width: 599px) {
    padding: 48px 16px;
  }

  @media (max-width: 480px) {
    > ${ModalButton} {
      display: block;
    }
  }
`

export default ModalBody
