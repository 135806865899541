import styled, { css } from "styled-components"
import theme from "../theme"
import { Headline } from ".."
import { Paragraph } from "../Typography"

const { media, section, colors } = theme

const size = css`
  max-width: ${section.large.size};
  padding: 0 0 ${section.large.indents};

  @media ${media.table} {
    padding: 0 12px ${section.medium.indents};
    max-width: ${section.medium.size};
  }
  @media ${media.mini} {
    padding: 0 12px ${section.small.indents};
    max-width: ${section.small.size};
  }
`

const full = css`
  max-width: ${section.fluid.size};
  padding: 0 20px ${section.fluid.indents};
  @media ${media.table} {
    padding: 0 12px ${section.medium.indents};
  }
  @media ${media.mini} {
    padding: 0 12px ${section.small.indents};
  }
`

const contrast = css`
  margin: 0;
  margin-bottom: 128px;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: ${colors.blue["600"]};

  @media ${media.table} {
    padding: 48px 12px;
    margin-bottom: 96px;
  }
  @media ${media.mini} {
    padding: 24px 12px;
    margin-bottom: 64px;
  }

  > ${Headline}, ${Paragraph} {
    color: ${colors.white};
  }
`

const Section = styled.section`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  margin: auto;
  width: 100%;

  > ${Headline} {
    font-weight: 800;
    max-width: 820px;
    margin-bottom: 48px;
    @media ${media.table} {
      margin-bottom: 32px;
    }
    @media ${media.mobile} {
      margin-bottom: 24px;
    }
  }

  ${({ fluid }) => (fluid ? full : size)};
  ${({ dark }) => (dark ? contrast : "")}
`

export default Section
