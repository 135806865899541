import styled from "styled-components"
import theme from "../theme"

const get = key => ({ color }) => theme.button.state[color][key]
const ModalButton = styled.button`
  font-family: Montserrat;
  display: block;
  width: 100%;
  text-align: center;
  outline: none;
  min-height: 56px;
  padding: 12px 24px;
  line-height: 1;
  border: 0;
  border-radius: 0 0 18px 18px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  margin: 0;

  color: ${get("text")};
  background-color: ${get("color")};
  :hover {
    background-color: ${get("hover")};
  }
  :active {
    background-color: ${get("pressed")};
  }
  :disabled {
    color: ${get("disabled")};
  }

  @media ${theme.media.mobile} {
    min-height: 48px;
  }

  @media (max-width: 480px) {
    border-radius: 12px;
  }
`

ModalButton.defaultProps = {
  color: "purple",
}

export default ModalButton
