import styled from "styled-components"
import { show } from "./animation"
import theme from "../theme"

const ModalOverlay = styled.div`
  position: fixed;
  width: calc(100% + 16px);
  height: 100%;
  overflow-y: scroll;
  z-index: 10001;
  padding: 16px 0;
  background: ${props =>
    props.theme.modalOverlay || theme.colors.overlay["50%"]};
  display: ${({ open }) => (open ? "flex" : "none")};
  justify-content: center;
  align-content: center;
  animation: ${show} 0.5s;
  left: 0;
  right: 0;
  bottom: 0;
  top: -70px;
  transform: translateY(70px);

  @media (max-width: 599px) {
    animation: none;
    padding: 0;
    background-color: ${theme.colors.white};
  }
`

export default ModalOverlay
