import styled from "styled-components"

export const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.headerBorder};

  @media (max-width: 636px) {
    display: none;
  }
`

export const Inner = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 48px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin: 0 auto;
  padding: 0 24px;

  .soc-container {
    margin: 0 24px;
  }
`

export { LogoContainer, SocLink, Nav, Box, Link, InnerLink } from "../../styled"
