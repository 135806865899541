export const getNodesByLocale = (nodes, locale) => {
  return nodes.reduce((acc, node) => {
    if (node.node_locale === locale) {
      acc.push(node)
    }

    return acc
  }, [])
}

export const getNodeByLocale = (nodes, locale) => {
  return nodes.reduce((acc, node) => {
    if (node.node_locale === locale) {
      return node
    }

    return acc
  }, {})
}
