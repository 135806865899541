import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Modal, ModalBody } from "src/uikit/Modal"
import { useLocale } from "src/common/hooks"
import { getNodesByLocale } from "src/common/getNodesByLocale"
import { Title, FormContainer } from "src/uikit/Modal/styled"
import CalendlyWidget from "../CalendlyWidget"

const ContactModal = ({
  isOpen = true,
  title = "",
  modalId = "default",
  onClose,
}) => {
  const locale = useLocale()

  const data = useStaticQuery(
    graphql`
      query QueryContactModal {
        allContentfulModalsContact {
          nodes {
            modalId
            title
            node_locale
            name
            lastName
            company
            companyEmail
            phone
            submitButton
            successTitle
            successCloseButton
            successBody {
              json
            }
            errorEmail
            errorPhoneField
            errorEmptyField
            portalId
            formId
          }
        }
      }
    `
  )

  const node = useMemo(() => {
    return getNodesByLocale(data.allContentfulModalsContact.nodes, locale).find(
      item => item.modalId === modalId
    )
  }, [data.allContentfulModalsContact.nodes, locale, modalId])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalBody>
          <Title>{title || node.title}</Title>
          <FormContainer>
            <CalendlyWidget/>
          </FormContainer>
        </ModalBody>
      </Modal>

    </>
  )
}

export default ContactModal
