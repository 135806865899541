import colors from "./colors"

export default {
  modal: {
    overlay: "rgba(0, 21, 83, 0.15)",
    size: "560px",
  },

  input: {
    color: "#F2F3FA",
    text: "#0A1C61",
    placeholder: colors.grey["600"],
    focus: colors.grey["200"],
    size: {
      large: "56px",
      small: "40px",
    },
  },

  avatar: {
    stroke: {
      cyan: colors.cyan["500"],
      purple: colors.purple["900"],
      yellow: colors.yellow["900"],
      blue: colors.blue["500"],
    },
    state: {
      small: {
        size: "88px",
        border: "6px",
      },
      large: {
        size: "112px",
        border: "8px",
      },
    },
  },

  floatButton: {
    size: "64px",
    shadow: colors.overlay["15%"],
    shadowHover: colors.overlay["25%"],
    state: {
      yellow: {
        text: colors.white,
        color: colors.yellow.darken,
        hover: colors.yellow["700"],
        pressed: colors.yellow.darken,
      },
    },
  },

  strokeButton: {
    text: colors.blue["600"],
    color: colors.grey["200"],
    hover: colors.grey["300"],
    pressed: colors.blue["400"],
    size: {
      large: "56px",
      small: "48px",
    },
  },

  button: {
    size: {
      large: "56px",
      small: "40px",
    },
    state: {
      inverse: {
        purple: {
          text: "#5855F4",
          color: colors.white,
          hover: "#6F6CFA",
          pressed: "#4B48F2",
        },
        cyan: {
          text: "#2FD6D3",
          color: "#001553",
          hover: "#02cccb",
          pressed: "#02b7ba",
        },
      },
      white: {
        text: "#5855F4",
        color: "#fff",
        hover: "#fff",
        pressed: "#fff",
      },
      cyan: {
        text: colors.white,
        color: colors.cyan["500"],
        hover: colors.cyan["400"],
        pressed: colors.cyan["600"],
        disabled: "rgba(255, 255, 255, 0.5)",
      },
      purple: {
        text: colors.white,
        color: "#5855F4",
        hover: "#6F6CFA",
        pressed: "#4B48F2",
        disabled: "rgba(255, 255, 255, 0.5)",
      },
      yellow: {
        text: colors.white,
        color: colors.yellow["900"],
        hover: colors.yellow["700"],
        pressed: colors.yellow.darken,
      },
      blue: {
        text: colors.white,
        color: colors.blue["500"],
        hover: colors.blue["400"],
        pressed: colors.blue["600"],
      },
      turquoise: {
        text: colors.white,
        color: "#02CCCB",
        hover: "#02CCCB",
        pressed: "#02CCCB",
      },
    },
  },
}
