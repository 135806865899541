import React, { useState, useEffect } from "react"
import Tooltip from "rc-tooltip"

import "./RCTooltip.css"

const RCTooltip = ({ id, children, placement, type, el, ...res }) => {
  const [pos, setPos] = useState(placement)
  const [isVisible, setIsVisible] = useState()

  const handleResize = () => {
    const inner = document.getElementById(id)
    const arrow = inner && inner.parentNode.firstChild
    const wrapper = inner && inner.parentNode.parentNode
    const rect = el && el.current.getBoundingClientRect()

    if (arrow && wrapper && rect && window.innerWidth <= 768) {
      const wrapperLeft = +wrapper.style.left.replace("px", "")
      const left = rect.left - wrapperLeft + rect.width / 2

      arrow.style.left = `${left}px`
    } else if (arrow && window.innerWidth > 768) {
      arrow.style.left = "50%"
    }
  }

  const handleVisibleChange = value => {
    setIsVisible(value)
    handleResize()
  }

  useEffect(() => {
    const handleScroll = () => {
      const offsetY = window.pageYOffset
      const rect = el && el.current.getBoundingClientRect()
      const top = rect && rect.top + offsetY

      if (top - offsetY < 80) {
        setPos(pos.replace("top", "bottom"))
      } else {
        setPos(pos.replace("bottom", "top"))
      }
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", handleScroll)

    if (!el.current) {
      return
    }

    const handleClick = () => {
      setIsVisible(true)
    }

    const { current } = el

    current.addEventListener("click", handleClick)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleScroll)

      if (!current) {
        return
      }

      current.removeEventListener("click", handleClick)
    }
  }, [])

  return (
    <Tooltip
      id={id}
      placement={pos}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      afterVisibleChange={handleVisibleChange}
      prefixCls={pos.includes("bottom") ? `${type}-bottom` : type}
      {...res}
    >
      {children}
    </Tooltip>
  )
}

export default RCTooltip
