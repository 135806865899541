import styled from "styled-components"
import theme from "../theme"

const { floatButton } = theme
const get = key => ({ color }) => floatButton.state[color][key]

const FloatButton = styled.button`
  border-radius: 50%;
  border: none;
  font-size: 2em;
  outline: none;

  width: ${floatButton.size};
  height: ${floatButton.size};
  box-shadow: 0 3px 8px -1px ${floatButton.shadow};

  color: ${get("text")};
  background-color: ${get("color")};

  :hover {
    background-color: ${get("hover")};
    box-shadow: 0 3px 8px -1px ${floatButton.shadowHover};
  }
  :active {
    background-color: ${get("pressed")};
  }
`

FloatButton.defaultProps = {
  color: "yellow",
}

export default FloatButton
