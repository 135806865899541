import { useIntl } from "gatsby-plugin-intl"

const LOCALES = {
  "en-us": "en-US",
  "ru-ru": "ru-RU",
  default: "en-US",
}

const useLocale = () => {
  const { locale } = useIntl()

  return LOCALES[locale] || LOCALES.default
}

export default useLocale
