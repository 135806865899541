import { css } from "styled-components"
import theme from "../theme"
import { FONT } from "src/developers/constants"

const { input, font } = theme
const styles = css`
  font-family: ${font};
  background-color: #f2f3fa;
  color: ${input.text} !important;

  width: 100%;
  font-size: 17px;
  line-height: 1.88;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px;
  border: 0;

  outline: none;

  ::placeholder {
    color: ${input.placeholder};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  :-webkit-autofill::first-line {
    font-family: ${FONT.PRIMARY};
    font-size: 17px;
    line-height: 1.88;
  }
`

export default styles
