const MINUTE = 60
const RADIX = 10

const formatTime = rawTime => {
  const time = parseInt(rawTime, RADIX)
  const minutes = parseInt(time / MINUTE, RADIX)
  const rawSeconds = parseInt(time % MINUTE, RADIX)
  const seconds = rawSeconds < 10 ? "0" + rawSeconds : rawSeconds

  return `${minutes}:${seconds}`
}

export { formatTime }
