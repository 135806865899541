import styled from "styled-components"

import { media } from "src/uikit"

export const Title = styled.h3`
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #142470;
  margin-top: 0;
  margin-bottom: 32px;

  @media ${media.mobile} {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
  }
`

export const Description = styled.div`
  margin-bottom: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  /* or 187% */

  text-align: center;
  letter-spacing: 0.4px;

  /* Color #1 / 900 */

  color: #0a1c61;
`

export const FormContainer = styled.form`
  margin-top: 32px;

  @media (max-width: 480px) {
    margin-top: 16px;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    flex-basis: 100%;
    margin-bottom: 24px;
  }

  > div:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 480px) {
    > div:last-child {
      margin-bottom: 14px;
    }
  }
`
