import styled from "styled-components"

export const Container = styled.div``

export {
  SkolkovoLogo,
  LogoContainer,
  SocLink,
  Nav,
  Box,
  Link,
  InnerLink,
} from "../../styled"
