import React, { useCallback, useEffect, useState } from "react"

import ContactModal from "../ContactModal"
import SubscribeModal from "../SubscribeModal"
import DownloadAssetModal from "../DownloadAssetModal"
import {
  SHOW_MODAL_EVENT,
  DOWNLOAD_ASSET_MODAL,
  SUBSCRIBE_MODAL,
  CONTACT_MODAL,
} from "./events"

const ModalsContainer = () => {
  const [state, setState] = useState({
    modal: "",
    options: {},
  })

  useEffect(() => {
    const handleShowModal = event => {
      setState({
        modal: event.detail.modal,
        options: event.detail.options,
      })
    }

    document.addEventListener(SHOW_MODAL_EVENT, handleShowModal)

    return () => {
      document.removeEventListener(SHOW_MODAL_EVENT, handleShowModal)
    }
  }, [setState])

  const handleCloseModal = useCallback(() => {
    setState({
      modal: "",
      options: {},
    })
  })

  const { options, modal } = state
  const { title, modalId } = options

  if (modal === CONTACT_MODAL) {
    return (
      <ContactModal
        title={title}
        modalId={modalId}
        onClose={handleCloseModal}
      />
    )
  }

  if (modal === SUBSCRIBE_MODAL) {
    return (
      <SubscribeModal
        title={title}
        modalId={modalId}
        onClose={handleCloseModal}
      />
    )
  }

  if (modal === DOWNLOAD_ASSET_MODAL) {
    return (
      <DownloadAssetModal
        modalId={modalId}
        title={title}
        onClose={handleCloseModal}
      />
    )
  }

  return null
}

export default ModalsContainer
