import styled from "styled-components"

import { media } from "src/uikit"
import { FONT } from "src/developers/constants"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.bannerBg || "#edf0ff"};
  padding: 8px 16px;
  height: 72px;

  .inner {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: 16px;
  }

  img {
    max-width: 100%;
    width: 62.4px;
    height: 56px;
    margin-right: 16px;
  }

  span {
    font-family: ${FONT.PRIMARY};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    margin-right: 12px;
    color: ${p => p.theme.bannerText || "rgba(0, 21, 83, 0.85)"};
  }

  a {
    color: ${p => p.theme.bannerLink || "#5855f4"};
    font-family: ${FONT.PRIMARY};
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  @media ${media.mobile} {
    .text {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    span {
      margin-bottom: 4px;
    }

    span {
      font-weight: normal;
    }

    span,
    a {
      font-size: 13px;
      line-height: 18px;
    }
  }
`
