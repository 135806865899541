export const SHOW_MODAL_EVENT = "SHOW_MODAL_EVENT"

export const CONTACT_MODAL = "ContactModal"
export const SUBSCRIBE_MODAL = "SubscribeModal"
export const DOWNLOAD_ASSET_MODAL = "DownloadAssetModal"

const showModal = (modal, options = {}) => {
  const event = new CustomEvent(SHOW_MODAL_EVENT, {
    detail: {
      modal,
      options,
    },
  })
  document.dispatchEvent(event)
}

export const getModalClickHandler = (modalName, options = {}) => {
  return showModal.bind(null, modalName, options)
}
