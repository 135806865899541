import styled from "styled-components"

import { media } from "src/uikit"

export const Title = styled.h3`
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #001553;
  margin-top: 0;
  margin-bottom: 32px;

  @media ${media.mobile} {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
  }
`

export const FormContainer = styled.form`
  margin-top: 32px;

  @media (max-width: 480px) {
    margin-top: 16px;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    flex-basis: calc(50% - 8px);
  }

  > div:nth-child(2n + 1) {
    margin-right: 8px;
  }

  > div:nth-child(2n) {
    margin-left: 8px;
  }

  > div:last-child {
    flex-basis: 100%;
    margin: 0;
  }

  @media (max-width: 480px) {
    > div,
    > div:nth-child(2n),
    > div:nth-child(2n + 1) {
      flex-basis: 100%;
      margin: 0;
    }

    > div:last-child {
      margin-bottom: 14px;
    }
  }
`
