import React, { useRef, useState, useCallback } from "react"
import TooltipStateless from "./TooltipStateless"
import getPosition from "./Positions"
import theme from "../theme"

const Tooltip = props => {
  const {
    size = "small",
    position = "top",
    bgColor = theme.colors.purple["500"],
    color = theme.colors.white,
    trigger = false,
    fontSize = "17px;",
    prerender,
    content,
    children,
    open,
    close,
    toggle,
    onOpen,
    onClose,
    onToggle,
    order = 100,
  } = props

  const ref = useRef(null)
  const tooltip = useRef(null)
  const [isOpen, toggleOpen] = useState(trigger)
  const Child = React.Children.only(children)
  const wrappedContent = prerender ? prerender(content) : content

  const events = {}
  if (open) events[open] = () => toggleOpen(true)
  if (close) events[close] = () => toggleOpen(false)
  if (toggle) events[toggle] = () => toggleOpen(!isOpen)

  useCallback(() => {
    const is = isOpen || trigger
    is ? onOpen && onOpen() : onClose && onClose()
    onToggle && onToggle(is)
  }, [isOpen, trigger])()
  const pos = position instanceof Function ? position() : position

  return (
    <>
      <TooltipStateless
        ref={tooltip}
        isOpen={trigger || isOpen}
        size={size}
        color={color}
        bgColor={bgColor}
        fontSize={fontSize}
        position={pos}
        order={order}
        {...getPosition(tooltip.current, ref.current, pos, 14)}
      >
        {wrappedContent}
      </TooltipStateless>
      {React.cloneElement(Child, { ...events, ref })}
    </>
  )
}

export default Tooltip
