import React from "react"
import styled from "styled-components"
import { CloseIcon } from "../../media"
import theme from "../theme"

const Box = styled.button`
  width: 48px;
  height: 48px;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: ${theme.colors.white};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  :hover {
    background-color: #f2f3fa;
  }

  :active {
    background-color: #e4e7fb;
  }

  @media (max-width: 1199px) {
    :hover {
      background-color: #fff;
    }
  }

  @media (max-width: 599px) {
    right: 6px;
    top: 6px;
  }
`

const ModalClose = props => (
  <Box {...props}>
    <CloseIcon />
  </Box>
)

export default ModalClose
