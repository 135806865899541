import React from "react"

import TwitterLogo from "../../assets/twitter-logo.inline.svg"
import GitHubLogo from "../../assets/github-logo.inline.svg"
import ThemeSwitch from "../../../ThemeSwitch"

import * as S from "./styled"

function RuHeader({ theme, content, changeTheme }) {
  return (
    <S.Container>
      <S.Inner>
        {content.links.map(item =>
          item.link.includes("http") ? (
            <S.Link
              id="Documentation"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.label}
            </S.Link>
          ) : (
            <S.InnerLink to={item.link}>{item.label}</S.InnerLink>
          )
        )}

        <div className="soc-container">
          <S.SocLink
            className="u-url"
            id="Twitter"
            href={content.twitterLink}
            target="_blank"
            rel="noopener noreferrer me"
          >
            <TwitterLogo />
          </S.SocLink>
          <S.SocLink
            className="u-url"
            id="Github"
            href={content.gitHubLink}
            target="_blank"
            rel="noopener noreferrer me"
          >
            <GitHubLogo />
          </S.SocLink>
        </div>
        {changeTheme && (
          <ThemeSwitch currentTheme={theme} onChange={changeTheme} />
        )}
      </S.Inner>
    </S.Container>
  )
}

export default RuHeader
