import styled from "styled-components"
import theme from "../theme"
import buttonStyle from "./buttonStyle"

const { button, media } = theme
const get = key => ({ color }) => button.state[color][key]

const Button = styled.button`
  ${buttonStyle};

  ${({ full }) => full && `width: 100%;`};
  height: ${({ size }) => (size ? button.size[size] : "56px")};
  background-color: ${get("color")};
  color: ${get("text")};

  @media ${media.mobile} {
    height: ${button.size.small};
  }

  :hover {
    background-color: ${get("hover")};
  }
  :active {
    background-color: ${get("pressed")};
  }
`

Button.defaultProps = {
  color: "purple",
  full: false,
}

export default Button
