import styled, { css } from "styled-components"

import theme from "src/uikit/theme"
import { media } from "src/uikit"

const get = key => ({ color, inverse }) =>
  inverse
    ? theme.button.state.inverse[color][key]
    : theme.button.state[color][key]

const buttonStyle = ({ size = "large" }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${size === "large" ? "20px 26px" : "12.5px 16px"};
  font-family: ${theme.font};
  width: fit-content;
  cursor: pointer;
  border: none;
  border-radius: ${size === "large" ? 12 : 8}px;
  text-transform: uppercase;
  font-size: ${size === "large" ? 15 : 13}px;
  line-height: ${size === "large" ? 16 : 15}px;
  font-weight: bold;
  text-align: center;
  outline: none;
  transition: 0.3s;
  margin: 0;
  white-space: nowrap;

  :disabled {
    color: rgba(255, 255, 255, 0.5);
  }

  @media ${media.mobile} {
    white-space: normal;
  }
`

export const Button = styled.button`
  ${props => buttonStyle(props)};

  ${({ full }) => full && `width: 100%;`};
  width: 280px;
  height: 56px;
  background-color: ${get("color")};
  color: ${get("text")};
  text-decoration: none;

  :not(:disabled):hover {
    background-color: ${get("hover")};
  }

  :active {
    background-color: ${get("pressed")};
  }

  @media (max-width: 599px) {
    width: 288px;
    height: 48px;
  }
`

Button.defaultProps = {
  color: "purple",
  full: false,
}

const inversedButtonStyle = ({ size = "large" }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${size === "large" ? "20px 28px" : "12.5px 16px"};
  font-family: ${theme.font};
  width: fit-content;
  cursor: pointer;
  border-radius: ${size === "large" ? 12 : 8}px;
  text-transform: uppercase;
  font-size: ${size === "large" ? 15 : 13}px;
  line-height: ${size === "large" ? 16 : 15}px;
  font-weight: bold;
  text-align: center;
  outline: none;
  transition: 0.3s;
  margin: 0;
  white-space: nowrap;
  text-decoration: none;

  @media ${media.mobile} {
    white-space: normal;
  }
`

export const InversedButton = styled.button`
  ${props => inversedButtonStyle(props)};

  ${({ full }) => full && `width: 100%;`};
  width: 280px;
  height: 56px;
  background-color: transparent;
  color: ${get("text")};
  border: 2px solid ${get("text")};

  :not(:disabled):hover {
    color: ${get("hover")};
    border-color: ${get("hover")};
  }
  :active {
    color: ${get("pressed")};
    border-color: ${get("pressed")};
  }

  @media (max-width: 599px) {
    height: 48px;
    width: 288px;
  }
`

InversedButton.defaultProps = {
  color: "purple",
  full: false,
}

export const StrokeButton = styled.button`
  font-family: ${theme.font};
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.4px;
  outline: none;
  transition: 0.3s color;
  cursor: pointer;
  background: none;
  color: ${props =>
    props.color === "purple" ? "#313C9D" : "rgba(255, 255, 255, 0.85)"};
  border: none;
  ${({ full }) => full && `width: 100%;`};
  text-decoration: underline;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  :not(:disabled):hover {
    color: ${props => (props.color === "purple" ? "#313C9D" : "#fff")};
  }

  @media ${media.mobile} {
    white-space: normal;
  }
`

StrokeButton.defaultProps = {
  full: false,
}
