import { graphql, useStaticQuery } from "gatsby"

import { useLocale } from "src/common/hooks"
import { getNodesByLocale } from "src/common/getNodesByLocale"

const usePosts = () => {
  try {
    const locale = useLocale()

    const data = useStaticQuery(graphql`
      query PostsQuery {
        posts: allContentfulBlogPosts(
          sort: { order: DESC, fields: createdAt }
        ) {
          nodes {
            id
            blogPostId
            bpAuthor
            title
            bpIntroduction {
              json
            }
            node_locale
            readLength
            category: newCategory {
              name
              categoryId
            }
            createdAt
            heroImage {
              fixed(width: 600) {
                src
                srcSet
              }
            }
          }
        }
      }
    `)

    const posts = getNodesByLocale(
      data.posts.nodes.filter(({ title }) => !!title),
      locale
    )

    const categories = posts.reduce((acc, cur) => {
      if (cur.category && !acc.has(cur?.category?.categoryId)) {
        acc.set(cur?.category?.categoryId, cur?.category?.name)
      }

      return acc
    }, new Map())

    return {
      posts: getNodesByLocale(
        data.posts.nodes.filter(({ title }) => !!title),
        locale
      ),
      categories,
    }
  } catch (err) {
    return { posts: [], categories: new Map() }
  }
}

export default usePosts
