import styled, { css } from "styled-components"

const fullSize = css`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  ${({ fit }) => fit && "object-fit: cover"};
  ${({ circle }) => circle && "border-radius: 50%"};
  ${({ full }) => full && fullSize}
`

Image.defaultProps = {
  circle: false,
  fit: false,
  full: true,
}

export default Image
