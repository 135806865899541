export default {
  white: "#fff",
  black: "#000",

  main: "#142470",

  cyan: {
    lighter: "#e4fff6",
    100: "#b6f5ed",
    200: "#89ebe4",
    300: "#5ce0dc",
    400: "#2fd6d3",
    500: "#02cccb",
    600: "#02b7ba",
    700: "#01a3aa",
    800: "#019099",
    900: "#007c89",
    darken: "#006878",
  },

  blue: {
    ligther: "#e5efff",
    100: "#c1cbeb",
    200: "#9da7d8",
    300: "#7883c4",
    400: "#545fb1",
    500: "#313c9d",
    600: "#27348e",
    700: "#1d2c7f",
    800: "#1d2c7f",
    900: "#0a1c61",
    darken: "#001553",
  },

  yellow: {
    lighter: "#fffcd6",
    100: "#fff9ab",
    200: "#fff680",
    300: "#fff355",
    400: "#fff02a",
    500: "#ffee00",
    600: "#ffe600",
    700: "#ffe000",
    800: "#ffd900",
    900: "#ffd300",
    darken: "#ffcc00",
  },

  purple: {
    lighter: "#e4e7ff",
    100: "#cdc6ff",
    200: "#b6a7ff",
    300: "#a087ff",
    400: "#8867ff",
    500: "#7247ff",
    600: "#6d3ff8",
    700: "#6938f1",
    800: "#6430ea",
    900: "#5f28e3",
    darken: "#5a21dc",
  },

  red: {
    100: "#f15668",
    200: "#f15668",
    300: "#f15668",
    400: "#f15668",
    500: "#7247ff",
    600: "#f15668",
    700: "#f15668",
    800: "#f15668",
    900: "#f15668",
  },

  grey: {
    lighter: "#f5f6f7",
    100: "#ebecee",
    200: "#e1e3e6",
    300: "#d7dbdd",
    400: "#cdd2d5",
    500: "#c4c9cd",
    600: "#a0a6ab",
    700: "#7e838a",
    800: "#5b5f68",
    900: "#393c47",
    darken: "#171a26",
  },

  overlay: {
    "15%": "rgba(0, 21, 83, 0.15)",
    "25%": "rgba(0, 21, 83, 0.25)",
    "50%": "rgba(0, 21, 83, 0.5)",
    "85%": "rgba(0, 21, 83, 0.85)",
  },
}
