import styled from "styled-components"
import theme from "../theme"

const { media, section } = theme
const Container = styled.section`
  margin: auto;

  max-width: ${section.large.size};
  padding: ${section.large.indents} 20px;

  @media ${media.table} {
    padding: ${section.medium.indents} 20px;
    max-width: ${section.medium.size};
  }
  @media ${media.mini} {
    padding: ${section.small.indents} 12px;
    max-width: ${section.small.size};
  }
`

export default Container
