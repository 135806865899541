import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import { useBreakpoint } from "../../common/hooks"

import DesktopHeader from "./components/DesktopHeader"
import MobileHeader from "./components/MobileHeader"
import RuHeader from "./components/RuHeader"
import * as S from "./styled"

const Header = ({ content, theme, openModal, changeTheme }) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const isMobile = useBreakpoint(1199)
  const { locale } = useIntl()

  const headerProps = {
    theme,
    content: content.header,
    openModal,
    changeTheme,
    isSearchFocused,
    setIsSearchFocused,
  }

  return (
    <>
      {locale === "ru-ru" && <RuHeader {...headerProps} />}
      <S.Container id="header">
        {isMobile ? (
          <MobileHeader {...headerProps} footerContent={content.footer} />
        ) : (
          <DesktopHeader {...headerProps} />
        )}
      </S.Container>
    </>
  )
}

export default Header
