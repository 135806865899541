import colors from "./colors"
const family = "Montserrat, 'sans-serif'"
const color = colors.blue["800"]

export default {
  font: family,

  link: {
    family,
    color: colors.purple["500"],
    hover: colors.purple["700"],
    background: colors.purple.lighter,
  },

  hero: {
    family,
    color,
    bold: 800,
    normal: 500,
    light: 200,
    large: {
      size: "176px",
      lineHeight: 1,
      letterSpacing: "-3.8px",
    },
    medium: {
      size: "144px",
      lineHeight: 1,
      letterSpacing: "3px",
    },
    small: {
      size: "88px",
      lineHeight: 1,
      letterSpacing: "-1.8px",
    },
  },

  headline: {
    family,
    color,
    bold: "bold",
    normal: 600,
    light: 500,
    large: {
      size: "32px",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    medium: {
      size: "40px",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    small: {
      size: "26px",
      lineHeight: 1.23,
      letterSpacing: "normal",
    },
  },

  title: {
    family,
    color,
    bold: 900,
    normal: 800,
    light: 300,
    large: {
      size: "60px",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    medium: {
      size: "48px",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    small: {
      size: "40px",
      lineHeight: 1.2,
      letterSpacing: "-1px",
    },
  },

  paragraph: {
    family,
    color,
    bold: 700,
    light: 400,
    large: {
      size: "20px",
      lineHeight: 1.4,
      letterSpacing: "0.6px",
    },
    medium: {
      size: "17px",
      lineHeight: 1.87,
      letterSpacing: "0.45px",
    },
    mediumMobile: {
      size: "15px",
      lineHeight: 1.9,
      letterSpacing: "0.45px",
    },
    small: {
      size: "13px",
      lineHeight: 1.85,
      letterSpacing: "0.4px",
    },
  },

  caption: {
    family,
    color,
    bold: 800,
    normal: 500,
    light: "normal",
    large: {
      size: "11px",
      lineHeight: 1.45,
      letterSpacing: "normal",
    },
    medium: {
      size: "10px",
      lineHeight: 1.4,
      letterSpacing: "noraml",
    },
    small: {
      size: "9px",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
  },

  overline: {
    family,
    color,
    bold: 800,
    normal: 600,
    light: 500,
    large: {
      size: "15px",
      lineHeight: 1.07,
      letterSpacing: "normal",
    },
    medium: {
      size: "13px",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    small: {
      size: "11.5px",
      lineHeight: 1.22,
      letterSpacing: "normal",
    },
  },

  label: {
    family,
    color: colors.grey["600"],
    bold: 800,
    normal: 500,
    light: 400,
    large: {
      size: "15px",
      lineHeight: 1.07,
      letterSpacing: "normal",
    },
    medium: {
      size: "13px",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    small: {
      size: "11.5px",
      lineHeight: 1.22,
      letterSpacing: "normal",
    },
  },
}
