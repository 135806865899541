import styled from "styled-components"
import theme from "../theme"

const { media } = theme
const Indent = styled.div`
  margin-top: ${({ lg }) => lg}px;

  @media ${media.table} {
    margin-top: ${({ md, lg }) => (md != null ? md : lg)}px;
  }
  @media ${media.mini} {
    margin-top: ${({ sm, md, lg }) =>
      sm != null ? sm : md != null ? md : lg}px;
  }
`

Indent.defaultProps = {
  lg: 0,
  md: null,
  sm: null,
}

export default Indent
