import colors from "./colors"
import layout from "./layout"
import typography from "./typography"
import components from "./components"

const media = {
  mini: `(max-width: ${layout.devices.mini})`,
  mobile: `(max-width: ${layout.devices.mobile})`,
  table: `(max-width: ${layout.devices.table})`,
  tablet: `(max-width: ${layout.devices.table})`,
  maxDesktop: `(max-width: ${layout.devices.desktop})`,
  desktop: `(min-width: ${layout.devices.desktop})`,
}

export default {
  ...components,
  ...layout,
  ...typography,
  colors,
  media,
}
