import styled from "styled-components"
import theme from "../theme"
import typography from "./typography"

const Hero = styled.h1`
  ${typography(theme.hero)}
`

const Title = styled.h2`
  ${typography(theme.title)}
`

const Headline = styled.h3`
  ${typography(theme.headline)}
`

const Caption = styled.p`
  ${typography(theme.caption)}
`

const Overline = styled.p`
  ${typography(theme.overline)};
  text-transform: uppercase;
`

const Paragraph = styled.p`
  ${typography(theme.paragraph)}
`

const Label = styled.label`
  ${typography(theme.label)}
`

export { Label, Hero, Title, Headline, Paragraph, Caption, Overline }
