import React from "react"
import styled from "styled-components"
import styles from "./styles"
import theme from "../theme"

import { FONT } from "src/developers/constants"

const { input, media } = theme
const Input = styled.input`
  ${styles}
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-family: ${FONT.PRIMARY};
  height: ${({ size }) =>
    size === "sm" ? input.size.small : input.size.large};

  @media ${media.mobile} {
    font-size: 15px;
    line-height: 28px;
    height: ${input.size.small};
  }

  ${({ isValid }) => isValid === false && "border: 1px solid #E63544;"}
`

const LabelContainer = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  height: 24px;
`

const RequiredField = styled.span`
  color: #e63544;
`

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 16px;
  margin-top: 4px;
  margin-bottom: 8px;

  @media ${media.mobile} {
    margin-top: 2px;
    margin-bottom: 6px;
  }
`

export const Error = styled.span`
  font-family: ${FONT.PRIMARY};
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;

  color: #e63544;

  @media ${media.mobile} {
    font-size: 11px;
    line-height: 16px;
  }
`

const Label = styled.label`
  font-family: ${FONT.PRIMARY};
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: rgba(0, 21, 83, 0.5);

  @media ${media.mobile} {
    font-size: 13px;
    line-height: 20px;
  }
`

export const InputComponent = ({
  error,
  label,
  id,
  isRequired = true,
  ...props
}) => (
  <div>
    {label !== undefined && (
      <LabelContainer>
        <Label htmlFor={id}>
          {label} {isRequired && <RequiredField>*</RequiredField>}
        </Label>
      </LabelContainer>
    )}
    <Input id={id} {...props} />
    <ErrorContainer>{!!error && <Error>{error}</Error>}</ErrorContainer>
  </div>
)

export default Input
