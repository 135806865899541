import React from "react"
import { Modal, ModalBody, ModalButton } from "../../uikit/Modal"
import { Title } from "../../uikit/Modal/styled"
import { Paragraph, Indent } from "../../uikit"
import WellDone from "../../images/icons/well-done.svg"

const SuccessModal = ({
  isOpen,
  toggle,
  title,
  content,
  body,
  closeButton,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalBody>
        <WellDone />
        <Indent sm={40} lg={40} />
        <Title>{title}</Title>
        <Indent sm={16} lg={16} />
        {content ? content : <Paragraph>{body}</Paragraph>}
        <Indent sm={32} />
        <ModalButton color="turquoise" onClick={toggle}>
          {closeButton}
        </ModalButton>
      </ModalBody>
      <ModalButton color="turquoise" onClick={toggle}>
        {closeButton}
      </ModalButton>
    </Modal>
  )
}

export default SuccessModal
