import styled from "styled-components"
import theme from "../theme"
import buttonStyle from "./buttonStyle"

const { strokeButton: button, media } = theme
const StrokeButton = styled.button`
  ${buttonStyle};

  background-color: #fff;
  color: ${button.text};
  border: 2px solid ${button.color};
  ${({ full }) => full && `width: 100%;`};

  height: ${button.size.large};
  @media ${media.mobile} {
    height: ${button.size.small};
  }

  :hover {
    border-color: ${button.hover};
  }
  :active {
    border-color: ${button.pressed};
  }
`

StrokeButton.defaultProps = {
  full: false,
}

export default StrokeButton
