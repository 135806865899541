import styled from "styled-components"
import styles from "./styles"
import theme from "../theme"

const Textarea = styled.textarea`
  ${styles};
  min-height: 194px;
  max-height: 448px;
  resize: none;
  ${({ isValid }) => isValid === false && "border: 1px solid #E63544;"}

  @media screen and ${theme.media.mobile} {
    font-size: 15px;
    min-height: 136px;
  }
`

export default Textarea
