import styled from "styled-components"
import theme from "../theme"

const Block = styled.section`
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  margin: ${({ mg }) => mg}px auto;
  width: 100%;
  max-width: ${({ lg }) => (lg != null ? lg + "px" : "100%")};
  @media ${theme.media.table} {
    max-width: ${({ md }) => (md != null ? md + "px" : "100%")};
  }
  @media ${theme.media.mini} {
    max-width: ${({ sm }) => (sm != null ? sm + "px" : "100%")};
  }
`

Block.defaultProps = {
  mg: 0,
}

export default Block
