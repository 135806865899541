export { default as CloseIcon } from "./images/icons/close.svg"
export { default as PlayIcon } from "./images/icons/play.svg"
export { default as PauseIcon } from "./images/icons/pause.svg"
export { default as FaceBookIcon } from "./images/icons/facebook.svg"
export { default as YouTubeIcon } from "./images/icons/youtube.svg"
export { default as LinkedIcon } from "./images/icons/linked.svg"
export { default as BurgerIcon } from "./images/icons/burger.svg"
export { default as PhoneIcon } from "./images/icons/phone.svg"
export { default as ArrowUp } from "./images/icons/arrow-up.svg"
export { default as ArrowDown } from "./images/icons/arrow-down.svg"
export { default as ArrowRight } from "./images/icons/arrow-right.svg"
export { default as ArrowLeft } from "./images/icons/arrow-left.svg"
export { default as TwitterIcon } from "./images/icons/twitter.svg"
