import { css } from "styled-components"
import theme from "../theme"

const buttonStyle = css`
  font-family: ${theme.font};
  width: fit-content;
  padding: 20px 28px;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  outline: none;
`

export default buttonStyle
