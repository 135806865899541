import styled from "styled-components"
import theme from "../theme"

const Link = styled.a`
  font-family: ${theme.link.family};
  color: ${theme.link.color};
  font-size: inherit;
  font-weight: bold;
  line-height: 1.88;
  letter-spacing: 0.5px;
  text-decoration: none;

  :hover {
    border-radius: 6px;
    color: ${theme.link.hover};
    background-color: ${theme.link.background};
  }
`

export default Link
