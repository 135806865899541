import React from "react"

import useContent from "src/developers/useContent"
import { useLocale } from "src/common/hooks"
import AwardsIcon from "./awards.inline.svg"

import * as S from "./styled"

const TopBanner = () => {
  const locale = useLocale()
  const content = useContent()

  return (
    <>
      {locale.includes("ru") ? (
        <S.Container id="top-banner">
          <div className="inner">
            <AwardsIcon />
            <div className="text">
              <span>Финалист в номинации «Рост выручки»</span>
              <a href="https://ai-russia.ru/nomination/revenue-growth">
                Подробнее
              </a>
            </div>
          </div>
        </S.Container>
      ) : (
        <S.Container id="top-banner">
          <div className="inner">
            <div className="text">
              <span>
                {content.topBanner.new}
                <a href={content.topBanner.playgroundLink}>
                  {content.topBanner.playgroundLabel}
                </a>
                {content.topBanner.text}
              </span>
            </div>
          </div>
        </S.Container>
      )}
    </>
  )
}

export default TopBanner
