import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

const DEFAULT_ID = "aiui-portal"
const Portal = ({ id = DEFAULT_ID, children }) => {
  const [doc, setDocument] = useState(null)
  useEffect(() => {
    setDocument(document)
  }, [])
  if (doc && !doc.getElementById(id)) {
    let container = document.getElementById(id)
    container = document.createElement("div")
    document.body.appendChild(container)
    container.id = id
    return createPortal(children, container)
  }
  if (doc && doc.getElementById(id)) {
    let container = document.getElementById(id)
    return createPortal(children, container)
  }
  return null
}

export default Portal
