export default {
  spacing: "8px",

  devices: {
    mini: "479px",
    mobile: "767px",
    table: "991px",
    desktop: "1439px",
  },

  section: {
    small: {
      indents: "64px",
      size: "100vw",
    },
    medium: {
      indents: "96px",
      size: "640px",
    },
    large: {
      size: "1200px",
      indents: "128px",
    },
    fluid: {
      indents: "128px",
      size: "100vw",
    },
  },
}
