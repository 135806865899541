import styled from "styled-components"
import theme from "../theme"

const Feedback = styled.p`
  padding: 0 0 2px 0;
  margin: 0;
  margin-bottom: 12px;

  @media ${theme.media.mobile} {
    padding-top: 2px;
    margin-bottom: 0;
  }

  text-align: left;
  font-size: 13px;
  line-height: 1.85;
  letter-spacing: 0.4px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  color: #da2340;
`

Feedback.defaultProps = {
  visible: false,
}

export default Feedback
