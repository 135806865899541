import styled from "styled-components"
import { Button, StrokeButton } from "../Button"
import theme from "../theme"
import { Input } from "."

const GroupInput = styled.div`
  display: flex;
  width: 100%;

  ${Input} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @media ${theme.media.mobile} {
      width: 100%;
      border-radius: 8px;
    }
  }

  ${Button}, ${StrokeButton} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @media ${theme.media.mobile} {
      width: 100%;
      border-radius: 8px;
    }
  }

  @media ${theme.media.mobile} {
    display: block;
  }
`

export default GroupInput
