import React, { useState, useEffect } from "react"
import Button from "src/newUikit/Button"

import * as S from "./styled"

const AcceptCookieForm = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const isAccepted = localStorage.getItem("acceptCookie") || false

    setIsOpen(!isAccepted)
  }, [setIsOpen]) 

  const handleClick = () => {
    localStorage.setItem("acceptCookie", "true")
    setIsOpen(false)
  }

  return (
    isOpen && (
      <S.Container>
        <div className="inner">
          <div className="text-container">
            <S.Paragraph>
              We use cookies for functional and analytical purposes. Please refer to our 
              <S.InnerLink to={`/privacy-policy`}> Privacy Policy </S.InnerLink>
              for details.
            </S.Paragraph>
          </div>
          <div className="divider"></div>
          <div className="buttons-container">
            <Button type="button" size="small" onClick={handleClick}>
              Accept
            </Button>
          </div>
        </div>
      </S.Container>
    )
  )
}

export default AcceptCookieForm
