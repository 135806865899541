import styled, { css } from "styled-components"
import { Link as GLink } from "gatsby"

import { media } from "src/uikit"
import { Button } from "src/newUikit/Button/styled"
import { FONT } from "src/developers/constants"

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  padding: 16px 24px;
  background-color: ${props => props.theme.cookieBg || "#fbfbfd"};
  border-top: 1px solid ${props => props.theme.cookieBorder || "#dee0e6"};

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
  }

  .divider {
    width: 1px;
    height: 100%;
    min-height: 40px;
    background-color: #dee0e6;
    border-radius: 2px;
    margin: 0 32px;

    display: none;
  }

  ${Button} {
    height: 32px;
    width: 120px;
    padding: 0;
  }

  @media ${media.tablet} {
    .inner {
      flex-direction: column;
    }

    .divider {
      display: none;
    }
  }
`

export const Paragraph = styled.p`
  font-family: ${FONT.PRIMARY};
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: ${props => props.theme.text || "rgba(0, 21, 83, 0.85)"};
  margin-right: 16px;

  @media ${media.tablet} {
    text-align: center;
  }
`

const linkStyle = css`
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-decoration: none;
  color: ${props => props.theme.text || "rgba(0, 21, 83, 0.85)"};

  &:hover {
    text-decoration: underline;
  }
`

export const InnerLink = styled(GLink)`
  ${linkStyle}
`

export const Link = styled.a`
  ${linkStyle}
`
