import React from "react"
import styled from "styled-components"
import theme from "../theme"
import Image from "../Image"

const state = type => theme.avatar.state[type]

const AvatarImage = styled(Image)`
  border-style: solid;
  border-color: ${({ color }) => theme.avatar.stroke[color]};
  border-width: ${({ size }) => state(size).border};
  width: ${({ size }) => state(size).size};
  height: ${({ size }) => state(size).size};
`

const Avatar = ({ src, ...props }) => (
  <picture>
    <AvatarImage className="lazyload" data-src={src} {...props} fit />
  </picture>
)

Avatar.defaultProps = {
  size: "large",
  color: "cyan",
  circle: true,
}

export default Avatar
