import React, { useMemo } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"

import { Modal, ModalBody, ModalButton } from "src/uikit/Modal"
import { InputComponent } from "src/uikit/Input"
import { getNodesByLocale } from "src/common/getNodesByLocale"
import { useLocale, useForm } from "src/common/hooks"

import { Title, FormContainer } from "src/uikit/Modal/styled"

const fields = ["firstname", "lastname", "phone", "company", "email"]

const labelsMap = {
  firstname: "name",
  lastname: "lastName",
  email: "companyEmail",
  company: "company",
  phone: "phone",
}

const DownloadAssetModal = ({ onClose, modalId, title = "" }) => {
  const locale = useLocale()
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulModalsDownloadAsset {
          nodes {
            modalId
            node_locale
            title
            name
            lastName
            company
            companyEmail
            phone
            submitButton
            successTitle
            successCloseButton
            successBody {
              json
            }
            errorEmail
            errorEmptyField
            errorPhoneField
            portalId
            formId
            downloadAsset {
              asset {
                file {
                  url
                  fileName
                }
                node_locale
              }
            }
          }
        }
      }
    `
  )
  const node = useMemo(
    () =>
      getNodesByLocale(
        data.allContentfulModalsDownloadAsset.nodes,
        locale
      ).find(item => item.modalId === modalId),
    [data.allContentfulModalsDownloadAsset.nodes, locale, modalId]
  )
  const {
    form,
    isLoading,
    isDisabled,
    onChange: handleChangeInput,
    onSubmit: handleSubmit,
  } = useForm({
    fields,
    portalId: node.portalId,
    formId: node.formId,
    errorEmptyField: node.errorEmptyField,
    errorPhoneField: node.errorPhoneField,
    errorEmailField: node.errorEmail,
    onSuccess: () => navigate(`/${modalId}-ty`),
  })

  const { errors } = form

  return (
    <Modal isOpen onClose={onClose}>
      <ModalBody>
        <Title>{title || node.title}</Title>
        <FormContainer>
          {fields.map(field => (
            <InputComponent
              key={field}
              isValid={errors[field] === undefined}
              type={field === "email" ? "email" : "text"}
              label={node[labelsMap[field]]}
              id={field}
              error={errors[field]}
              value={form[field]}
              onChange={handleChangeInput}
            />
          ))}
        </FormContainer>
        <ModalButton
          color="purple"
          disabled={isDisabled || isLoading}
          onClick={handleSubmit}
        >
          {node.submitButton}
        </ModalButton>
      </ModalBody>
      <ModalButton
        color="purple"
        disabled={isDisabled || isLoading}
        onClick={handleSubmit}
      >
        {node.submitButton}
      </ModalButton>
    </Modal>
  )
}

export default DownloadAssetModal
