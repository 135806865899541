import { useIntl } from "gatsby-plugin-intl"

import en from "./locales/en-us.json"
import ru from "./locales/ru-ru.json"

const LOCALES = {
  "en-us": en,
  "ru-ru": ru,
  default: en,
}

const useContent = () => {
  const { locale } = useIntl()

  return LOCALES[locale] || LOCALES.default
}

export default useContent
