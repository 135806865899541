import { css } from "styled-components"
import theme from "../theme"

const style = (text, size) => css`
  font-size: ${text[size].size};
  line-height: ${text[size].lineHeight};
  letter-spacing: ${text[size].letterSpacing};
`

const typography = text => css`
  color: ${text.color};
  font-family: ${text.family};
  font-weight: ${({ weight }) => text[weight || "normal"]};
  margin: 0;
  position: relative;
  
  > mark {
    color: inherit;
    padding: 4px;
    border-radius: 12px;
    background-color: #fdf3a0;
  }

  ${({ size }) => style(text, size || "large")}

  @media ${theme.media.table} {
    ${({ size }) => size !== "small" && style(text, "medium")}
  }
  @media ${theme.media.mobile} {
    ${({ size }) =>
      size === "medium" && text.mediumMobile
        ? style(text, "mediumMobile")
        : style(text, "small")}
  }
`

export default typography
