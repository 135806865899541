import styled from "styled-components"

import { colors } from "src/uikit"

import ProgressBar from "./ProgressBar"

export const Player = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  margin-right: 24px;
  margin-bottom: 16px;
  text-align: left;
  padding: 12px;
  background: #5855f4;
  border-radius: 12px;

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
`

export const PlayerSlider = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`

export const PlayerAudio = styled.audio`
  display: none;
`

export const PlayerControls = styled.div``

export const Circle = styled(ProgressBar)`
  position: absolute;
  top: 20px;
  left: 20px;
  transform: translate(-50%, -50%);
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  pointer-events: none;
  z-index: 4;
`

export const UnderlayCircle = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  transform: translate(-50%, -50%);
  border: solid 2px #ffffff26;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 3;

  ${PlayerControls}:hover & {
    border-color: ${({ isPlaying }) =>
      isPlaying ? "#ffffff26" : colors.white};
    transition: 0.3s;
  }
`

export const PlayerButton = styled.button`
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  border: 0;
  color: #fff;
  background: transparent;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  transform: translate(-50%, -50%);
  outline: none;
`

export const AudionInfo = styled.div`
  margin-left: 12px;
  line-height: 22px;
`

export const Name = styled.p`
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.4px;
  color: #fff;
  margin: 0;
  line-height: 1.87;

  @media (max-width: 740px) {
    font-size: 13px;
  }
`

export const Time = styled.p`
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.4px;
  color: hsla(0, 0%, 100%, 0.5);
  margin: -5px 0 0;
`

export const CurrentTime = styled.span``
export const Divider = styled.span`
  margin: 0 4px;
`
export const TotalTime = styled.span``
