import React, { useEffect } from "react"
import Portal from "../Portal"
import CloseButton from "./ModalClose"
import ModalOverlay from "./ModalOverlay"
import ModalWrap from "./ModalWrap"
import { getScrollbarWidth } from "./utils"

const Modal = props => {
  const { isOpen, onClose, onScroll, children } = props
  const undelegate = e => e.stopPropagation()

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth()
    const headerEl = document.getElementById("header")

    if (!headerEl) {
      return
    }

    if (isOpen) {
      document.body.style.paddingRight = `${scrollbarWidth}px`
      document.body.style.overflow = "hidden"
      headerEl.style.width = `calc(100% - ${scrollbarWidth}px)`
    } else {
      document.body.style.paddingRight = ""
      document.body.style.overflow = ""
      headerEl.style.width = "100%"
    }

    return () => {
      document.body.style.paddingRight = ""
      document.body.style.overflow = ""
      headerEl.style.width = "100%"
    }
  }, [isOpen])

  return (
    <Portal id="modals">
      <ModalOverlay open={isOpen} onClick={onClose}>
        <ModalWrap onClick={undelegate} onScroll={onScroll}>
          <CloseButton onClick={onClose} />
          {children}
        </ModalWrap>
      </ModalOverlay>
    </Portal>
  )
}

export default Modal
