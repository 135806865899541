import React from "react"

// eslint-disable-next-line react/display-name
const ProgressBar = React.forwardRef(
  ({ offset = 295.95, color = "#fff" }, ref) => (
    <svg
      id="circle"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        ref={ref}
        id="seekbar"
        stroke={color}
        fill="none"
        d="M50,2.9L50,2.9C76,2.9,97.1,24,97.1,50v0C97.1,76,76,97.1,50,97.1h0C24,97.1,2.9,76,2.9,50v0C2.9,24,24,2.9,50,2.9z"
        strokeWidth="4.5"
        strokeDasharray="295.95"
        strokeDashoffset={offset}
      ></path>
    </svg>
  )
)

export default ProgressBar
