import React, { useState } from "react"
import { Modal, ModalBody, ModalButton } from "../../uikit/Modal"
import { Title, FormContainer, Description } from "./styled"
import { InputComponent } from "../../uikit/Input"
import { graphql, useStaticQuery } from "gatsby"
import { getNodesByLocale } from "../../common/getNodesByLocale"
import { useLocale, useForm } from "src/common/hooks"
import SuccessModal from "../../common/SuccessModal"
import Content from "../../templates/BlogPost/components/Content"

const fields = ["email"]

const labelsMap = {
  email: "emailHint",
}

const SubscribeModal = ({ title = "", modalId = "default", onClose }) => {
  const locale = useLocale()
  const [status, setStatus] = useState(0)
  const data = useStaticQuery(
    graphql`
      query QuerySubscribeModal {
        allContentfulModalsSubscribe {
          nodes {
            modalId
            node_locale
            title
            body
            emailHint
            subscribeButton
            successTitle
            successCloseButton
            successBody {
              json
            }
            errorEmail
            errorEmptyField
            portalId
            formId
          }
        }
      }
    `
  )
  const node = getNodesByLocale(
    data.allContentfulModalsSubscribe.nodes,
    locale
  ).find(item => item.modalId === modalId)
  const {
    form,
    isLoading,
    isDisabled,
    onChange: handleChangeInput,
    onSubmit: handleSubmit,
  } = useForm({
    fields,
    portalId: node.portalId,
    formId: node.formId,
    errorEmptyField: node.errorEmptyField,
    errorEmailField: node.errorEmail,
    onSuccess: () => setStatus(1),
  })

  if (status === 0) {
    const { errors } = form

    return (
      <Modal isOpen onClose={onClose}>
        <ModalBody>
          <Title>{title || node.title}</Title>
          <FormContainer>
            <Description>{node.body}</Description>
            {fields.map(field => (
              <InputComponent
                key={field}
                isValid={errors[field] === undefined}
                type={field === "email" ? "email" : "text"}
                label={node[labelsMap[field]]}
                error={errors[field]}
                id={field}
                value={form[field]}
                onChange={handleChangeInput}
              />
            ))}
          </FormContainer>
          <ModalButton
            color="purple"
            disabled={isDisabled || isLoading}
            onClick={handleSubmit}
          >
            {node.subscribeButton}
          </ModalButton>
        </ModalBody>
        <ModalButton
          color="purple"
          disabled={isDisabled || isLoading}
          onClick={handleSubmit}
        >
          {node.subscribeButton}
        </ModalButton>
      </Modal>
    )
  }

  return (
    <SuccessModal
      title={node.successTitle}
      body={<Content json={node.successBody.json} />}
      closeButton={node.successCloseButton}
      isOpen
      toggle={onClose}
    />
  )
}

export default SubscribeModal
