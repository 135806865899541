import styled from "styled-components"
import { flip } from "./animation"
import ModalButton from "./ModalButton"

const Wrap = styled.div`
  width: 60vw;
  z-index: 100;
  margin: auto;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  animation: ${flip} 0.3s;
  position: relative;

  @media (max-width: 599px) {
    > ${ModalButton} {
      display: none;
    }

    width: 100vw;
    border-radius: 0;
    animation: none;
    margin: 0;
  }
`

export default Wrap
